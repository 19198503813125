import { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import CategoryCard from 'components/blog/category-card/CategoryCard'
import ArticleSection from 'components/article/article-section/ArticleSection'
import FeaturedArticle from 'components/article/featured-article/FeaturedArticle'
import { Language } from 'types/language'
import type { BlogProps } from './types'
import styles from './Blog.module.scss'
import { Heading } from '@boltenergy-be/design-system'
import { mapCmsBlogArticleToArticle } from 'utils/cms'

const Blog = ({ content, categories, articles }: BlogProps) => {
  // Local state
  const [categoryToShow, setCategoryToShow] = useState<string>(null)

  // Next router
  const router = useRouter()
  const { locale, query, asPath } = router

  // Filter articles & categories
  const filteredArticles = useMemo(
    () => (categoryToShow ? articles.filter((article) => article.category?.slug === categoryToShow) : articles),
    [articles, categoryToShow]
  )
  const filteredCategories = useMemo(
    () => categories.filter((category) => articles.some((article) => article.category?.slug === category.attributes.slug)),
    [categories, articles]
  )

  // Update articles filtering if "c" query string changes
  useEffect(() => {
    const { c: queryCategory } = query

    if (queryCategory) {
      setCategoryToShow(queryCategory as string)
    } else {
      setCategoryToShow(null)
    }
  }, [query])

  return (
    <>
      {/* SEO */}
      <Head>
        <link rel="canonical" href={`https://www.boltenergie.be/${locale}${asPath}`} />
      </Head>

      <main>
        {/* FEATURED */}
        {!!content.attributes.featuredBlogArticle.data && (
          <FeaturedArticle article={mapCmsBlogArticleToArticle(content.attributes.featuredBlogArticle.data)} />
        )}

        {!!articles?.length && (
          <>
            {/* CATEGORIES */}
            <section className={classNames('section', styles.categories)}>
              <div className="container">
                <Heading as="h2" variant="h3">
                  {content?.attributes.articlesTitle}
                </Heading>
                {!!filteredCategories?.length && filteredCategories?.length > 1 && (
                  <nav className={styles['categories-nav']}>
                    {filteredCategories.map((cat) => {
                      return <CategoryCard key={cat.id} language={locale as Language} category={cat} categoryToShow={categoryToShow} />
                    })}
                  </nav>
                )}
              </div>
            </section>

            {/* ARTICLES */}
            <ArticleSection paginate={!categoryToShow} articles={filteredArticles} className={styles.articles} />
          </>
        )}
      </main>
    </>
  )
}

export default Blog
