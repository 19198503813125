import Link from 'next/link'
import dayjs from 'dayjs'
import classNames from 'classnames'
import Dotdotdot from 'react-dotdotdot'
import styles from './FeaturedArticle.module.scss'
import homePageStyles from './FeaturedArticleHome.module.scss'
import { FeaturedArticleProps } from './types'
import Author from 'components/author/Author'
import Tag from 'components/tag/Tag'
import parse from 'html-react-parser'
import Img from 'components/img/Img'
import { Heading } from '@boltenergy-be/design-system'
import { CmsArticleTypes } from 'types/cms/enums'

const FeaturedArticleContent = ({ article, asContentBlock, isHomeVariant }: FeaturedArticleProps) => {
  //determine which stylesheet to use based on where the page is presented
  const activeStyleSheet = isHomeVariant ? homePageStyles : styles

  return (
    <div
      className={classNames(activeStyleSheet['featured-article'], {
        [activeStyleSheet.borderless]: article.type === CmsArticleTypes.BLOG_INTERNAL,
        [activeStyleSheet['content-block']]: asContentBlock
      })}
    >
      <Link href={article.destination} className={activeStyleSheet.article}>
        {article.thumbnail && (
          <figure className={activeStyleSheet['thumbnail-wrapper']}>
            <Img
              fill
              priority
              publicId={article.thumbnail.data.attributes.provider_metadata.public_id}
              className={activeStyleSheet.thumbnail}
              style={{ objectFit: 'cover' }}
              alt={`thumbnail - ${article.title}`}
              sizes="(max-width: 620px) 100vw, (max-width: 1120px) 66vw, 50vw"
            />
          </figure>
        )}
        <div className={activeStyleSheet.content}>
          {article.category && (
            <Tag transparentBackground color={article.category?.color} className={activeStyleSheet.tag}>
              {article.category?.title}
            </Tag>
          )}
          <Heading as="h2" variant="h3">
            {article.title}
          </Heading>
          <Dotdotdot clamp={6} className={activeStyleSheet.description}>
            {parse(article.description)}
          </Dotdotdot>
          <Author date={dayjs(article.publishedAt).format('DD/MM/YYYY')} name={article.author.firstName} image={article.author.avatarUrl} />
        </div>
      </Link>
    </div>
  )
}

const FeaturedArticle = ({ asContentBlock, article, isHomeVariant }: FeaturedArticleProps) => {
  return (
    <section
      className={classNames('section', styles.section, {
        'is-dark': article.type === CmsArticleTypes.BLOG_INTERNAL,
        [styles.rounded]: asContentBlock
      })}
    >
      {!asContentBlock ? (
        <div className="container">
          <FeaturedArticleContent {...{ asContentBlock, article, isHomeVariant }} />
        </div>
      ) : (
        <FeaturedArticleContent {...{ asContentBlock, article, isHomeVariant }} />
      )}
    </section>
  )
}

export default FeaturedArticle
