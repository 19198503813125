import Layout from 'components/layout/Layout'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Blog from 'features/blog/Blog'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import type { BlogProps } from 'features/blog/types'
import type { SeoProps } from 'components/seo/types'
import { cmsLib } from 'lib'
import { mapCmsBlogArticlesToArticles, mapCmsBlogExternalArticlesToArticles, sortArticlesOnPublishedDate } from 'utils/cms'

const BlogPage = (props: BlogProps) => {
  // Constants
  const { seo } = props.content.attributes
  const SEO: SeoProps = seo && {
    description: seo.metaDescription,
    image: seo.seoImage,
    title: seo.seoTitle
  }

  return (
    <Layout seo={SEO}>
      <Blog {...props} />
    </Layout>
  )
}

export const getStaticProps = async ({ locale }) => {
  try {
    // Fetch page content, categories & articles
    const [content, categories, blogArticles, blogExternalArticles] = await Promise.all([
      cmsLib.getBlogPage(locale),
      cmsLib.getBlogCategories(locale),
      cmsLib.getBlogArticles(locale),
      cmsLib.getBlogExternalArticles(locale)
    ])

    return {
      props: {
        articles: sortArticlesOnPublishedDate([
          ...mapCmsBlogArticlesToArticles(blogArticles),
          ...mapCmsBlogExternalArticlesToArticles(blogExternalArticles)
        ]),
        categories,
        content: content ?? null,
        ...(await serverSideTranslations(locale))
      } satisfies BlogProps,
      revalidate: 10 // re-generate page when a request comes in, once every 10 seconds
    }
  } catch (error) {
    return {
      props: {
        articles: [],
        categories: [],
        content: null,
        ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES, 'projects']))
      } satisfies BlogProps,
      revalidate: 10 // re-generate page when a request comes in, once every 10 seconds
    }
  }
}

export default BlogPage
